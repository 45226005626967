.modal {
        width: 80%;
        height: 410px;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        position: absolute;
        background: #FFF;

        @media screen and (min-width: 768px) {
            width: 45%;
            height: 410px;
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            position: absolute;
            background: #FFF;
        }
}

.modal-overlay{
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.botao {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    

    @media screen and (min-width: 768px) {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F2F2F2;
    }

    .botao-desktop {
        width: 70%;
        border: none;
        border-radius: 5px;
        height: 55px;
        background: #032144;
        color: white;
        font-size: 18px;
        cursor: pointer;

        @media screen and (min-width: 768px) {
            width: 30%;
        }
        
    }
}

.modal-privacidade{
    text-align: center;
    font-size: 12px;
    color: #000000;
    padding-top: 20px;
}

.box-modal {
    background: #FFFFFF;
    // width: 100%;
    height: 350px;
    display: inline-block;
    padding: 0 30px;

    @media screen and (min-width: 768px) {
        background: #FFFFFF;
        // width: 100%;
        height: 330px;
        display: block;
        padding: 0 30px;
    }

    .modal-titulo{
        text-align: center;
        font-size: 10px;
        color: #032144;
        line-height: 5px;

        @media screen and (min-width: 768px) {
            text-align: center;
            font-size: 18px;
            color: #032144;
            line-height: 5px;
        }
    }

    .form-info{
    //    width: 100%;

        .input-form{
            display: block;
            border-radius: 4px;
            background: #EDEDED;
            width: 100%;
            height: 40px;
            padding: 0 10px;
            // margin: 0;
            margin-bottom: 20px;

            @media screen and (min-width: 768px) {
                display: block;
                border-radius: 4px;
                background: #EDEDED;
                width: 100%;
                height: 40px;
                margin-bottom: 20px;
                padding: 0;
            }

        }

        label{
            color: #032144;
            font-weight: 600;
        }

        .div-form-button{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .form-button{
            width: 70%;
            height: 45px;
            color: #FFFFFF;
            border: none;
            border-radius: 5px;
            background: #032144;

            @media screen and (min-width: 768px) {
                width: 40%;
                height: 45px;
                color: #FFFFFF;
                border: none;
                border-radius: 5px;
                background: #032144;
                cursor: pointer;
            }
            
        }
    }
}

.modal-button-close{
    display: flex;
    width: 100%;
    justify-content: right;
    background: #FFFF;

    .form-button-close{
        background: #FFFF;
        border: none;
    }

}