.banner{
    padding-top: 95px;
    width: 100%;

    .banner-desktop {
        // padding: 100px 0;
        // padding-top: 5px;
        img {
            width: 100%;
        }
    
            .banner-principal {
                width: 100%;
                padding-top: 95px;
                // z-index: 10;
            }
    
            .poligon {
                margin-top: -15px;
                width: 100%;
            }
    }
}