.beneficios {
    background-image: url(../../../public/assets/imagens/misc/car-left.png);
    position: relative;
    width: 100%;
    min-height: 446px;
    display: flex;
    align-items: center;
    
    &::after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(3, 33, 68, 0.8);

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    @media screen and (min-width: 768px) {
        position: relative;
        width: 100%;
        height: inherit;
        display: flex;
        align-items: center;
        background: #FFF;
        opacity: 1;
    }

    .imagem-beneficios{
       display: none;

        @media screen and (min-width: 768px) {
            position: relative;
            opacity: 1;
            display: block;
        }
    }

    .descritivo {   
        padding: 0px 12px;
        top: 0;
        position: absolute;
        z-index: 2;

        @media screen and (min-width: 768px) {
            position: relative;
            padding: 0 45px;
            position: relative;
        }

        .titulo-descritivo {
            display: none;

            @media screen and (min-width: 768px) {
                display: block;
                color: #032144;
                text-align: justify;
                font-size: 45px;
            }
    
        }

        .titulo-descritivo-mobile{
            display: block;
            color: #FFFF;
            text-align: center;
            font-size: 22px;

            @media screen and (min-width: 768px) {
                display: none;
            
            }
        }

        .texto-descritivo {
            color: #FFFF;
            font-size: 14px;
            text-align: justify;

            @media screen and (min-width: 768px) {
                color: #032144;
            }
        }

    }
}