.topo {
    background: white;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    z-index: 999;

    @media screen and (min-width: 768px) {
        background: white;
        width: 100%;
        min-height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        z-index: 999;
    }

    .logoSite {
        margin-right: 50px;
        width: 50%;

        @media screen and (min-width: 768px) {
            margin-left: 40px;
            width: 48%;
        }
    }

    .botoes {
        display: none;
        
        @media screen and (min-width: 768px) {
            width: 50%;
            display: flex;
            justify-content: space-evenly;
            padding: 0;
        }

        .contato {
            width: 70%;
            padding: 0;
            border: none;
            border-radius: 5px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            font-size: 18px;
            box-shadow: 2px 2px 2px #999;
            cursor: pointer;
        }

        .icon {
            padding-left: 12px;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #032144;
            border-radius: 6px;
            width: 70%;
            z-index: 1;

            .number-color{
                color: #4DB1E3;
            }

            .number-link {
                border-top: 2px solid #4DB1E3;
            }
          }

          .dropdown {
            position: relative;
            display: inline-block;
            width: 100%;
          }

          .whatsapp-contents {
            position: relative;
            display: inline-block;
            width: 100%;

            a {
                text-decoration: none;
                color: white;
            }
          }

        .dropdown-content a {
            color: white;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            font-size: 12px;
          }
          
          .dropdown-content a:hover {
            background-color: #4DB1E3;

            .number-color{
                color: white;
            }
        }
          
          .dropdown:hover .dropdown-content {
            display: block;
          }
          
          .dropdown:hover .dropbtn {
            background-color: #032144;
          }

        .icon-whats {
            padding-right: 12px;
        }

        .fixo {
            // width: 20%;
            background: #032144;
            color: white;
        }
        
        .whats {
            // width: 20%;
            background: #5EC262;
            color: white;
        }
    }

    .menu {
        height: 40px;
        padding: 0;
        margin-right: 40px;
    }

    .navegador{
        width: 350px;
        display: none;
        flex-direction: column;
        background: white;
        height: calc(100vh - 100px);
        position: absolute;
        top: 100px;
        right: 0;

        &.active {
            display: flex;
        }

        .opcao{
            list-style: none;

            .lista{
                color: #565656;
                font-size: 25px;
                padding: 15px 0;
                border-bottom: 1px solid rgba(77, 177, 227, 0.6);

                .referencia{
                    text-decoration: none;
                }
            }
        }
    }

}