.espaco-botao {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;

    @media screen and (min-width: 768px) {
        display: none;
    }

    .mobile-content {
        width: 100%;
        display: flex;
        justify-content: center;
        bottom: 10px;
        position: fixed;
        z-index: 999;

        .botao-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 40px;
            // margin: 0 10px;
            height: 80px;
            width: 80px;
            text-align: center;
           
            
        }

        .moble-contato {
            background: #4DB1E3;
            margin: 0 90px 0 0;
        }

        .moble-whatsapp {
            background: #5EC262;
            margin: 0 0 0 90px;
        }

        img {
            width: 50%;
        }

    }


}

.modal-mobile {
    width: 80%;
    height: 270px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background: #032144;
    border-radius: 6px;

    @media screen and (min-width: 768px) {
        display: none;
    }
}

.modal-overlay{
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-info {
        padding: 0;
        margin: 10px 0;
        font-family: 'Montserrat', sans-serif;
        font

        .modal-number-mobile {
            color: #4DB1E3;
        }
    }

    .midium-info{
        border-top: 2px solid #4DB1E3;
        border-bottom: 2px solid #4DB1E3;
        padding: 20px 0;
        width: 90%;
        text-align: center;
    }

    .button-close {
        width: 100%;
        display: none;
        justify-content: flex-end;

        .close-button-modal-mobile {

        }
    }
}

.modal-title {
    color: white;
}

.modal-info {
    color: white;
}