.dropdown-service{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 125px;
    height: inherit;
    padding: 10px 0;

    @media screen and (min-width: 768px) {
        display: none;
    }

    .dropdown {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 5px 0;

        .dropdown-button{
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: #032144;
            color: #FFF;
            width: 70%;
            padding: 10px 0;
            border: 3px solid #074388;

        }

        .icon-info{
            width: 15%;
        }
    
        .dropdown-content {
            background-color: #032144;
            border-radius: 6px;
            width: 70%;
            z-index: 1;

            .dropdown-text {
                background: #FFF;
                color: #333333;
                padding: 5px 0;
                margin: 0;

            }
    
        }
    
    }

}


