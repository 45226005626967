.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: inherit;
    background-color: #F2F2F2;

    .container {
        width: 80%;
        max-width: 1200px;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #032144;
        margin-top: 150px;
        padding: 20px 0;
        gap: 10px;

        @media only screen and (min-width: 768px) {
            width: 100%;
        }

        span {
            font-size: 24px;
            font-weight: 600;
            line-height: 35px;
            text-align: center;
            
            @media only screen and (min-width: 768px) {
                font-size: 40px;
                line-height: 49px;
            }
        }

        hr {
            border: 1px solid rgba(77, 177, 227, 0.6);
            width: 290px;

            @media only screen and (min-width: 768px) {
                width: 800px;
            }
        }

        p {
            font-weight: 500;
            font-size: 19px;
            line-height: 23px;
            text-align: justify;
        }

        .cards-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1200px;
            height: inherit;
            text-align: center;

            // gap: 0px;

            .card {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                width: 260px;
                height: 338px;
                border-radius: 2px;
                background-color: #fff;
                line-height: 15px;
                padding: 0 10px;
                margin: 10px;

                .card-header {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    flex-direction: column;

                    span {
                        font-size: 22px;
                        color: #032144;
                        font-weight: 700;
                    }

                    p {
                        font-size: 13px;
                        font-weight: 300;
                        color: #032144;
                        text-align: center;
                        margin-top: 0;
                    }

                    hr {
                        width: 100%;
                        border: 1px solid #E0E8E7;
                    }
                }

                .card-content {
                    width: 100%;
                    height: inherit;
                    padding: 15px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 35px;
                        border-radius: 2px;
                        background-color: #032144;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 500;
                        text-decoration: none;
                    }
                }

            }
        }

    }

    .item {

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }

}