.especialista {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F2F2F2;
    min-height: 80px;

    @media screen and (min-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F2F2F2;
        min-height: 145px;
    }

        .especialista-titulo {
            color: #032144;
            font-size: 22px;
            font-weight: 400;
            letter-spacing: 0;

            @media screen and (min-width: 768px) {
                color: #032144;
                font-size: 45px;
                font-weight: 400;
                letter-spacing: 3px;
            }
        }
}