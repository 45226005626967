.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;

    .container {
        width: 80%;
        max-width: 1200px;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #032144;
        margin-top: 150px;
        gap: 10px;

        @media only screen and (min-width: 768px) {
            width: 100%;
        }

        span {
            font-weight: 600;
            font-size: 40px;
            line-height: 49px;
        }

        hr {
            border: 1px solid rgba(77, 177, 227, 0.6);
            width: 290px;

            @media only screen and (min-width: 768px) {
                width: 400px;
            }
        }

        p {
            font-weight: 500;
            font-size: 19px;
            line-height: 23px;
            text-align: justify;
        }
    }

    .logos {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 50%;
        
    }
}