// .pos {
//     background: rgba(3, 33, 68, 0.8);
//     @media screen and (min-width: 768px) {
//         background: none;
//     }    
// }

.pos-venda {
    background-image: url(../../../public/assets/imagens/misc/venda.png);
    background-size: cover;
    position: relative;
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    

    @media screen and (min-width: 768px) {
        width: 100%;
        height: inherit;
        display: flex;
        align-items: center;
        background: #F2F2F2;
    }

    &::after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(3, 33, 68, 0.8);

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    .imagem-pos{
        opacity: 0.8;

        img {
            display: none;


            @media screen and (min-width: 768px) {
                width: 660px;
                display: block;
            }
        }

        @media screen and (min-width: 768px) {
            position: relative;
            opacity: 1;
        }
    }

    .descritivo {   
        padding: 0 20px 0 20px;
        color: #FFFF;
        z-index: 2;

        @media screen and (min-width: 768px) {
            padding: 0 50px 0 80px;
        }

        .titulo-venda {
            color: #FFFF;
            text-align: center;
            font-size: 38px;
            padding: 0 0 10px 0;
            margin: 0;

            @media screen and (min-width: 768px) {
                color: #032144;
                position: relative;
                padding: 0 0 20px 0;
            }
        }

        .texto-venda {
            color: #FFFF;
            font-size: 18px;
            text-align: justify;
            padding: 0;
            margin: 0;

            @media screen and (min-width: 768px) {
                color: #032144;
                position: relative;
                padding: 0 45px;
                position: relative;
                padding: 0 0 20px 0;
            }
        }

    }
}