.servicos {
    display: none;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        background: #F2F2F2;
        padding-top: 25px;
    }

    .servicos-desktop {

        margin: 0 20px;
        // width: calc(100% / 4);
        width: 262px;

        .icon {
            width: 85px;
            margin-bottom: 16px;
        }
        
        .content {
            min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid #074388;
            flex: 1;
            padding: 0 16px;
        }

        .titulo {
            background: #032144;
            color: white;
            margin: 0;
            width: 100%;
            padding: 8px 0;

        }

        .lista {
           display: flex;
           flex-direction: column;
           justify-content: center; 
           align-content: center;
           list-style-type: none;
           width: 100%;
           margin-left: 0;
           padding: 0 8px;
        }

        li {
            // margin-bottom: 20px;
            margin-bottom: 5px;
            line-height: 35px;
        }
    }

    
}