.carrossel {
    width: 100%;
    display: block;
    margin-bottom: 30px;

    .react-multi-carousel-dot-list{

        display: none;

        @media screen and (min-width: 768px) {
            display: flex;
        }
    }

        .carrossel-titulo {
            color: #032144;
            font-size: 30px;

            @media screen and (min-width: 768px) {
                color: #032144;
                font-size: 40px;
                font-weight: 300px;
            }
        }

        .carrossel-all {
            padding: 20px 0;

            .carrossel-box {


                .carrossel-img {
                    width: 10%;
                    height: auto;

                }
            }
        }

        
}

.custom-dot-list-style{
    
    .react-multi-carousel-dot{
        button {
            background: rgbargba(217, 217, 217, 1);
            border-color: rgba(77, 177, 227, 0.31);
    
            &:hover{
                background: rgba(77, 177, 227, 0.31);
            }
        }

        &--active{
            button{
                background: rgba(77, 177, 227, 0.31);
            }
        }
    }

}