.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: inherit;

    .container {
        width: 80%;
        max-width: 1200px;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #032144;
        margin-top: 150px;
        gap: 10px;

        @media only screen and (min-width: 768px) {
            width: 100%;
        }

        span {
            font-weight: 600;
            font-size: 40px;
            line-height: 49px;
        }

        hr {
            border: 1px solid rgba(77, 177, 227, 0.6);
            width: 290px;

            @media only screen and (min-width: 768px) {
                width: 600px;
            }
        }

        p {
            font-weight: 500;
            font-size: 19px;
            line-height: 23px;
            text-align: justify;
        }

        .contact-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            width: 100%;
            max-width: 992px;
            height: inherit;
            text-align: center;

            // gap: 0px;

            .contact {
                span {
                    font-size: 22px;
                    color: #032144;
                    font-weight: 500;
                }

                p {
                    font-size: 18px;
                    font-weight: 300;
                    color: #032144;
                    text-align: center;
                }
            }
        }

    }

    .item {

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }

    .slider {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 35px;
    }
}

.glide__arrow--right {
    position: absolute;
    right: 21%;
    top: 43%;
    cursor: pointer;
}

.glide__arrow--left {
    position: absolute;
    left: 21%;
    top: 43%;
    cursor: pointer;
}

.hide {
    display: none !important;
}