.rodape-info {

    .rodape-azul {
        background: #4DB1E3;
        padding: 15px 0;

        .nome-parvi {
            width: 100%;
        }

    }

    .local {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #032144;
        padding: 25px 0;

        .rodape-local {
            color: white;
            margin: 0;
            line-height: 35px;
            letter-spacing: 2px;
        }

        .direitos {
            color: white;
            margin: 0;
            line-height: 35px;
            letter-spacing: 2px;
        }
    }
}